import { registerLocaleData } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '@services/_locale.service';
import { TranslationService } from '@services/_translation.service';

import localeEl from '@angular/common/locales/el'
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aimodosia-app';

  constructor(
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer,
    private translateService: TranslateService,
    private _localeService: LocaleService,
    private _translationService: TranslationService
  ) {

    this.matIconRegistry.addSvgIcon(
      'el_flag',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/flags/el.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'en_flag',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/flags/en.svg')
    );

    const lang = 'el';
    registerLocaleData(localeEl, lang);
    this._localeService.locale = lang;

    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
    this._translationService.initializeLanguage(lang);

    this.activatedRoute.queryParams.subscribe(params => {
      console.log(`Language: `, params['lang'] || 'el')
      if (params['lang']) setTimeout(() => {
        this._translationService.setLanguage(params['lang'] || 'el');
      }, 200)
    });

  }
}
