export enum MessageStatus {
  "SUCCESS" = "SUCCESS",
  "ERROR" = "ERROR",
  "WARNING" = "WARNING"
}

export enum MessageResponse {
  "OK" = "OK",
  "CANCEL" = "CANCEL"
}

export interface MessageInterface {
  status: MessageStatus,
  message: string
}

export enum SuccessMessage {
  "ASK_RESTORATION" = "ASK_RESTORATION",
  "COMPLETE_RESTORATION" = "COMPLETE_RESTORATION",
  "PASSWORD_UPDATED" = "PASSWORD_UPDATED",
  "PROFILE_UPDATED" = "PROFILE_UPDATED",
  "PARTNER_CREATED" = "PARTNER_CREATED",
  "PARTNER_ACTIVATED" = "PARTNER_ACTIVATED",
  "PARTNER_DEACTIVATED" = "PARTNER_DEACTIVATED",
  "PARTNER_INVITED" = "PARTNER_INVITED",
  "ACCOUNT_DELETED" = "ACCOUNT_DELETED",
  "PARTNERS_INITIALIZED" = "PARTNERS_INITIALIZED",
  "EVENT_CREATED" = "EVENT_CREATED",
  "EVENT_UPDATED" = "EVENT_UPDATED",
  "EVENT_DELETED" = "EVENT_DELETED",
  "BOOKING_CREATED" = "BOOKING_CREATED",
  "BOOKING_UPDATED" = "BOOKING_UPDATED",
  "BOOKING_DELETED" = "BOOKING_DELETED"
}

export enum ErrorMessage {
  "BAD_REQUEST" = "BAD_REQUEST",
  "UNAUTHORIZED" = "UNAUTHORIZED",
  "FORBIDDEN" = "FORBIDDEN",
  "UNPROCESSABLE_ENTITY" = "UNPROCESSABLE_ENTITY",
  "SERVER_ERROR" = "SERVER_ERROR",
  "UNKNOWN_ERROR" = "UNKNOWN_ERROR",
  "ALREADY_REGISTERED" = "ALREADY_REGISTERED",
  "WRONG_CREDENTIALS" = "WRONG_CREDENTIALS",
  "INVALID_TOKEN" = "INVALID_TOKEN",
  "INVALID_DATETIME" = "INVALID_DATETIME",
  "INVALID_DATE" = "INVALID_DATE",
  "INVALID_TIME" = "INVALID_TIME",
  "BOOKING_UNAVAILABLE" = "BOOKING_UNAVAILABLE",
  "NO_VALUE_CHANGED" = "NO_VALUE_CHANGED"
}

export enum WarningMessage {
  "ACTIVATE_PARTNER" = "ACTIVATE_PARTNER",
  "DEACTIVATE_PARTNER" = "DEACTIVATE_PARTNER",
  "INVITE_PARTNER" = "INVITE_PARTNER",
  "DELETE_ACCOUNT" = "DELETE_ACCOUNT",
  "REMOVE_EVENT" = "REMOVE_EVENT",
  "REMOVE_BOOKING" = "REMOVE_BOOKING"
}

export enum NoDataMessage {
  "NO_PARTNERS" = "NO_PARTNERS",
  "NO_PARTNER" = "NO_PARTNER",
  "NO_EVENTS" = "NO_EVENTS",
  "NO_EVENT" = "NO_EVENT",
  "NO_BOOKINGS" = "NO_BOOKINGS",
  "NO_BOOKING" = "NO_BOOKING",
}