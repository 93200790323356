export const environment = {
  production: true,

  // Production
  URL_API: `https://api.aimodosia365.gr`,
  URL_APP: `https://platform.aimodosia365.gr`

  // Staging
  // URL_API: `https://api-aimodosia365-gr.dev-sociality.gr`,
  // URL_APP: `https://app-aimodosia365-gr.dev-sociality.gr`
};
